import { useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Typography,
} from "@mui/material"
import css from "./DatosSeguridad.module.css"

const DatosSeguridad = ({ accesoAlumno }) => {
  const [isExpanded, setExpanded] = useState(true)

  const handleChange = (_event, isExpanded) => setExpanded(isExpanded)

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<i className="material-icons">expand_more</i>}>
        <div className={css.label}>
          <Typography className={css.title} variant="h2">
            Seguridad
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {accesoAlumno?.password ? (
          <Alert icon={<i className='material-icons'>check</i>} className={css.alert} severity='info'>
            El alumno tiene configuarada una contraseña.
          </Alert>
        ) : (
          <Alert icon={<i className='material-icons'>clear</i>} className={css.alert} severity='error'>
            El alumno no tiene configuarada una contraseña.
          </Alert>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default DatosSeguridad;
