import { useQuery } from "@tanstack/react-query"
import DatosAcceso from "./components/DatosAcceso/DatosAcceso"
import DatosSeguridad from "./components/DatosSeguridad/DatosSeguridad"
import PageLoading from "../../../components/PageLoading/PageLoading"
import Accesos from "./components/Accesos/Accesos"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { getAccesoClassfy } from "../../../utils/api/accesoClassfy"
import FormacionHeader from "../../../components/FormacionHeader/FormacionHeader"
import useFormacion from "../../../hooks/useFormacion"
import css from "./Classfy.module.css"

const Classfy = () => {

  const { id } = useFormacion()
  const notification = useNotification()

  const { isLoading, data: accesoFormacion = {} } = useQuery({
    queryKey: ["classfy", "acceso-classfy", id],
    queryFn: () => getAccesoClassfy(id)
      .catch((err) => {
        // Este es un error conocido, lo ignoramos
        if (err === "No se ha encontrado ningún documento con los datos especificados") return {}
        notification.error({ title: "Error obteniendo los datos de Classfy", content: err })
        return {}
      }),
  })

  return (
    <PageLoading isLoading={isLoading}>
      <div className={css.main}>
        <FormacionHeader label="Classfy" />
        <div className={css.content}>
          <div className={css.accordion}>
            <DatosAcceso accesoFormacion={accesoFormacion} />
          </div>
          <div className={css.accordion}>
            <DatosSeguridad accesoFormacion={accesoFormacion} />
          </div>
          <div className={css.accordion}>
            <Accesos acceso={accesoFormacion} />
          </div>
        </div>
      </div>
    </PageLoading>
  )
}

export default Classfy
