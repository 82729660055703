import { Card, CardContent, Chip, Typography } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { useNotification } from "../../context/NotificationManager/NotificationManager"
import PageLoading from "../../components/PageLoading/PageLoading"
import TextLabel from "../../components/form/TextLabel/TextLabel"
import { getProfesor } from "../../utils/api/profesores"
import { dateToStringLong, getDateFromObjectId } from "../../utils/date"
import css from './Perfil.module.css'

const AsignaturaIcon = ({ tipo })=> (
  <i className={`${css.icon} material-icons`}>
    {tipo === 'CIENCIAS' && 'science'}
    {tipo === 'CIENCIAS_SALUD' && 'biotech'}
    {tipo === 'ARTES' && 'palette'}
    {tipo === 'IDIOMAS' && 'translate'}
    {tipo === 'LETRAS' && 'history_edu'}
    {tipo === 'OTROS' && 'school'}
  </i>
)

const asignaturaLabel = ({ nombre, nivel, subnivel })=> {
  if (subnivel) return `${nombre} (${nivel} - ${subnivel})`
  return `${nombre} (${nivel})`
}


const Perfil = () => {

  const notification = useNotification()

  const { isLoading, data: perfil={} } = useQuery({
    queryKey: ['personal', 'profesor'], 
    queryFn: ()=> getProfesor()
      .then(datos=> ({
          ...datos,
          fecha: getDateFromObjectId(datos.id),
      }))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar tu perfil', content: err })
        return {}
      })
  })

  const hasAsignaturas = perfil.asignaturas && perfil.asignaturas.length > 0

  return (
    <PageLoading isLoading={isLoading}>
      <div className={css.main}>
        <Typography variant='h5' className={css.title}>
          Mi perfil
        </Typography>
        <div className={css.content}>
          <Card className={css.card}>
            <CardContent className={css.cardContent}>
              <Typography variant='h6' className={css.cardTitle}>
                Mis datos
              </Typography>
              <TextLabel
                className={css.input}
                label='Fecha de registro'
                disabled
                value={dateToStringLong(perfil.fecha)}
              />
              <TextLabel
                className={css.input}
                label='Nombre'
                disabled
                value={perfil.nombre}
              />
              <TextLabel
                className={css.input}
                label='Email'
                disabled
                value={perfil.email}
              />
            </CardContent>
          </Card>
          {hasAsignaturas && (
            <Card className={css.card}>
              <CardContent className={css.cardContent}>
                <Typography variant='h6' className={css.cardTitle}>
                  Mis asignaturas
                </Typography>
                <div className={css.asignaturas}>
                  {perfil.asignaturas
                    .sort((a,b)=> a.nombre.localeCompare(b.nombre))
                    .map(asignatura=> (
                      <Chip
                        key={asignatura.id}
                        className={css.chip}
                        data-status={asignatura.activa ? 'active' : 'inactive'}
                        icon={<AsignaturaIcon tipo={asignatura.tipo} />}
                        label={asignaturaLabel(asignatura)}
                      />
                    ))
                  }
                </div>
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </PageLoading>
  )
}

export default Perfil