import {  useQuery } from "@tanstack/react-query"
import AlumnoHeader from "../../../components/AlumnoHeader/AlumnoHeader"
import PageLoading from "../../../components/PageLoading/PageLoading"
import DatosAcceso from "./components/DatosAcceso/DatosAcceso"
import Accesos from "./components/Accesos/Accesos"
import DatosSeguridad from "./components/DatosSeguridad/DatosSeguridad"
import useAlumno from "../../../hooks/useAlumno"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { getAccesoClassfy } from "../../../utils/api/accesoClassfy"
import css from "./Classfy.module.css"

const Classfy = () => {
  const { id } = useAlumno()
  const notification = useNotification()

  const { isLoading, data: accesoAlumno = {} } = useQuery({
    queryKey: ["classfy", "acceso-classfy", id],
    queryFn: () => getAccesoClassfy(id)
    .catch((err) => {
      // Este es un error conocido, lo ignoramos
      if (err === "No se ha encontrado ningún documento con los datos especificados") return {}
      notification.error({ title: "Error obteniendo los datos de Classfy", content: err })
      return {}
    }),
  })

  return (
    <PageLoading isLoading={isLoading}>
      <div className={css.main}>
        <AlumnoHeader label="Classfy" />
        <div className={css.content}>
          <div className={css.accordion}>
            <DatosAcceso accesoAlumno={accesoAlumno}/>
          </div>
          <div className={css.accordion}>
            <DatosSeguridad accesoAlumno={accesoAlumno}/>
          </div>
          <div className={css.accordion}>
            <Accesos accesoAlumno={accesoAlumno}/>
          </div>
        </div>
      </div>
    </PageLoading>
  )
}

export default Classfy
