import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { dateToString, dateToWeekday, getToday } from "../../utils/date"
import { getClasesFecha } from "../../utils/api/clases"
import { getTotales } from "../../utils/model/asistencias"
import { createNotaProfesor } from "../../utils/api/alumnos"
import { addValoracionPrueba } from "../../utils/api/leads"
import { getMostRelevantHour } from "../../utils/model/horario"
import { isClassfy } from "../../utils/academia"
import { isBlank } from "../../utils/string"
import { getAsistenciaLeadList, getAsistenciaList, getAsistenciaTotals, updateAsistencia, updateAsistenciaLead } from "../../utils/api/asistencias"
import FormacionRow from "./components/FormacionRow/FormacionRow"
import FechaSelector from "../../components/form/FechaSelector/FechaSelector"
import PageLoading, { LoadingSpinner } from "../../components/PageLoading/PageLoading"
import Tabs from "../../components/Tabs/Tabs"
import ClaseRow from "./components/ClaseRow/ClaseRow"
import PruebaRow from "./components/PruebaRow/PruebaRow"
import ModalNuevaNotaProfesor from "../../modals/ModalNuevaNotaProfesor/ModalNuevaNotaProfesor"
import ModalValoracionPrueba from "../../modals/ModalValoracionPrueba/ModalValoracionPrueba"
import { useNotification } from "../../context/NotificationManager/NotificationManager"
import useResponsive from "../../hooks/useResponsive"
import css from './Planificacion.module.css'
import { getAsistenciaFormacionList } from "../../utils/api/asistenciasFormacion"

const TabContent = ({ 
  pruebas,
  asistencias, 
  formaciones,
  centro,
  isLoading,
  onAsistenciaChange,
  onPruebaChange,
  onAsistenciaClick,
  onPruebaClick
})=> {

  const [open, setOpen] = useState('')

  const { isScreenBiggerThan } = useResponsive()

  if (isLoading) return <LoadingSpinner />

  return (
    <TableContainer className={css.tabContent}>
      <Table stickyHeader className={css.table} size='small'>
        <TableHead>
        <TableRow>
          <TableCell className={css.cell}>Alumno</TableCell>
          <TableCell 
            className={css.cell}
            data-visibility={isScreenBiggerThan('sm') ? 'visible' : 'invisible'}
          >
            Asignatura
          </TableCell>
          <TableCell 
            className={css.cell}
            data-visibility={isScreenBiggerThan('md') ? 'visible' : 'invisible'}
          >
            Tipo de clase
          </TableCell>
          <TableCell className={css.cell} align="center">Asistencia</TableCell>
          <TableCell className={css.cell} align="center">Opciones</TableCell>
          <TableCell className={css.cell}></TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
          {formaciones
            .sort((p1, p2)=> p1.formacion.localeCompare(p2.formacion))
            .map(asistencia=> (
              <FormacionRow 
                key={asistencia.id}
                asistencia={asistencia}
                isOpen={open === asistencia.id}
                onOpen={()=> setOpen(open === asistencia.id ? '' : asistencia.id)}
              />
            ))}
          {pruebas
            .sort((p1, p2)=> p1.feedbackNombre.localeCompare(p2.feedbackNombre))
            .map(asistencia=> (
              <PruebaRow 
                key={asistencia.feedbackId}
                asistencia={asistencia}
                centro={centro}
                isOpen={open === asistencia.feedbackId}
                onOpen={()=> setOpen(open === asistencia.feedbackId ? '' : asistencia.feedbackId)}
                onChange={onPruebaChange}
                onClick={onPruebaClick}
              />
            ))}
          {asistencias
            .sort((a1, a2)=> a1.alumno.localeCompare(a2.alumno))
            .map(asistencia=> (
              <ClaseRow 
                key={asistencia.id}
                asistencia={asistencia}
                centro={centro}
                isOpen={open === asistencia.id}
                onOpen={() => setOpen(open === asistencia.id ? '' : asistencia.id)}
                onChange={onAsistenciaChange}
                onClick={onAsistenciaClick}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const TabLabel = ({ hora, total })=> {
  if (!total) return hora
  const detalles = getTotales({ total })
  return (
    <div className={css.tabLabel}>
      <div>{hora}</div>
      <div className={css.tabLabelDetalles}>
        {detalles.map(detalle=> (
          <span className={css.tabLabelElement} data-tipo={detalle.type} key={detalle.type}>
            {detalle.value}
          </span>
        ))}
      </div>
    </div>
  )
}

const Planificacion = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [fecha, setFecha] = useState(getToday())
  const [hora, setHora] = useState(null)

  const [asistenciaSeleccionada, setAsistenciaSeleccionada] = useState(null)
  const [action, setAction] = useState('')

  const { 
    isLoading: isHorarioLoading, 
    data: horario=[] 
  } = useQuery({
    queryKey: ['planificacion', 'clase-profesor', dateToString(fecha)], 
    queryFn: ()=> getClasesFecha({ fecha: dateToString(fecha) })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar el horario', content: err })
        return []
      })
  })

  const { data: totals={} } = useQuery({
    queryKey: [ 'planificacion', 'asistencia', 'list', 'totals', dateToString(fecha)], 
    enabled: fecha !== null,
    queryFn: ()=> getAsistenciaTotals({ fecha: dateToString(fecha) })
      .then(datos=> datos)
      .catch(()=> ({}))
  })

  const { isFetching: isAsistenciasLoading, data: asistenciaList=[] } = useQuery({
    queryKey: [ 'planificacion', 'asistencia', 'list', dateToString(fecha), hora], 
    enabled: fecha !== null && hora !== null,
    queryFn: ()=> getAsistenciaList({ fecha: dateToString(fecha), hora })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las asistencias', content: err })
        return []
      })
  })

  const { isFetching: isPruebasLoading, data: pruebaList=[] } = useQuery({
    queryKey: [ 'planificacion', 'asistencia', 'feedback', 'list', dateToString(fecha), hora],
    enabled: fecha !== null && hora !== null,
    queryFn: ()=> getAsistenciaLeadList({ fecha: dateToString(fecha), hora })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las pruebas', content: err })
        return []
      })
  })

  const { isFetching: isFormacionesLoading, data: formacionList=[] } = useQuery({
    queryKey: [ 'planificacion', 'asistencia', 'formacion', 'list', dateToString(fecha), hora],
    enabled: fecha !== null && hora !== null,
    queryFn: ()=> getAsistenciaFormacionList({ fecha: dateToString(fecha), hora })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las formaciones', content: err })
        return []
      })
  })

  const { mutate: modificarAsistencia } = useMutation({
    mutationFn: updateAsistencia,
    onSuccess: (_result, { fecha, multiple })=> {
      // Invalidamos el total de asistencias y las asistencias de la pestaña actual
      queryClient.invalidateQueries({
        queryKey: [ 'planificacion', 'asistencia', 'list' ],
        type: 'active',
      })
      // Borramos los datos de cache de las otras horas si nos llega el "mutliple" (que puede afectar a varias horas)
      if (multiple) {
        queryClient.removeQueries({
          queryKey: [ 'planificacion', 'asistencia', 'list', fecha ],
          type: 'inactive'
        })
      }
    },
    onError: err=> {
      notification.error({ title: 'Error al actualizar la asistencia', content: err })
    }
  })

  const { mutate: modificarPrueba } = useMutation({
    mutationFn: updateAsistenciaLead,
    onSuccess: ()=> {
      queryClient.invalidateQueries({
        queryKey: [ 'planificacion', 'asistencia', 'feedback', 'list', dateToString(fecha), hora],
      })
    },
    onError: err=> {
      notification.error({ title: 'Error al actualizar la prueba', content: err })
    }
  })

  const { isLoading: isValoracionUpdating, mutate: updateValoracionPrueba } = useMutation({
    mutationFn: addValoracionPrueba,
    onSuccess: ()=> {
      notification.success({ title: 'Valoración enviada', content: 'Se ha enviado la valoración de la prueba' })
      setAsistenciaSeleccionada({})
      setAction('')
      queryClient.invalidateQueries({
        queryKey: [ 'planificacion', 'asistencia', 'feedback', 'list', dateToString(fecha), hora ],
      })
    },
    onError: err=> {
      notification.error({ title: 'Error al enviar la valoración', content: err })
    }
  })

  const { isLoading: isCreandoNota, mutate: crearNota } = useMutation({
    mutationFn: createNotaProfesor,
    onSuccess: ()=> {
      notification.success({ title: 'Nota creada', content: 'Se ha añadido a la ficha del alumno' })
      setAsistenciaSeleccionada({})
      setAction('')
    },
    onError: err=> {
      notification.error({ title: 'Error al crear la nota', content: err })
    }
  })

  const horaActual = getMostRelevantHour(horario.map(clase=> clase.hora))

  // Un profe puede estar en 2 centros a la vez, porque uno de ellos es CLASSFY
  const centrosActuales = horario.filter(clase=> clase.hora === hora).map(h=> h.centro)

  useEffect(()=> {
    if (!hora) setHora(horaActual)
  }, [hora, horaActual])

  useEffect(()=> {
    setHora(null)
  }, [fecha])

  const handleAsistenciaClick = (asistenciaAlumno, asistenciaId)=> {
    const asistenciaModificar = asistenciaList.find(asistencia=> asistencia.id === asistenciaId)
    // Enviamos información extra para luego poder invalidar la cache correctamente
    modificarAsistencia({
      id: asistenciaId,
      asistencia: asistenciaAlumno,
      fecha: dateToString(asistenciaModificar.fecha),
      multiple: true,
    })
  }

  const handlePruebaClick = (asistenciaAlumno, feedbackId)=> {
    const pruebaModificar = pruebaList.find(asistencia=> asistencia.feedbackId === feedbackId)
    modificarPrueba({
      id: feedbackId,
      asistencia: asistenciaAlumno,
      fecha: dateToString(pruebaModificar.fecha),
      hora: pruebaModificar.hora,
    })
  }

  const handleAsistenciaAction = (asistencia, action)=> {
    setAsistenciaSeleccionada(asistencia)
    setAction(action)
  }

  const handlePruebaAction = (asistencia, action)=> {
    setAsistenciaSeleccionada(asistencia)
    setAction(action)
  }

  const handleModalClose = ()=> {
    setAsistenciaSeleccionada(null)
    setAction('')
  }

  const handleValoracionPrueba = ({ nota })=> {
    if (isValoracionUpdating) return
    updateValoracionPrueba({
      id: asistenciaSeleccionada.feedbackId, 
      fecha: dateToString(fecha), 
      hora: asistenciaSeleccionada.hora, 
      valoracion: nota, 
    })
  }

  const handleAddNota = ({ nota })=> {
    if (isCreandoNota) return
    crearNota({ alumno: asistenciaSeleccionada.alumnoId, nota })
  }

  const horarioPlanificacion = horario
    .reduce((acc, hora)=> {
      /* 
      * Quitamos los duplicados del horario, 
      * pero si hay 2 horas a la vez, el centro es el que no sea CLASSFY
      */
      const horaExistente = acc.find(h=> h.hora === hora.hora)
      if (!horaExistente) return [...acc, hora]
      const horaActualizada = {
        ...horaExistente,
        centro: isClassfy(hora.centro) ? horaExistente.centro : hora.centro
      }
      return [...acc.filter(h=> h.hora !== hora.hora), horaActualizada]
    }, [])
    .sort((h1, h2)=> h1.hora.localeCompare(h2.hora))

  return (
    <PageLoading isLoading={isHorarioLoading}>
      <div className={css.header}>
        <div>
          <p className={css.date}>
            Clases del día {dateToString(fecha)} ({dateToWeekday(fecha)})
          </p>
          {centrosActuales.length > 0 && (
            <p className={css.location}>
              {centrosActuales.map(centro=> (
                <span className={css.centro} key={centro}>
                  <i className='material-icons'>{isClassfy(centro) ? 'laptop' : 'school'}</i> {centro}
                </span>
              ))}
            </p>
          )}
        </div>
        <FechaSelector
          className={css.fechaSelector}
          label='Cambiar fecha'
          value={null}
          onChange={setFecha}
        />
      </div>
      {horario.length === 0 && (
        <div className={css.error}>
          <p className={css.errorMessage}>
            No hay horario configurado para este día
          </p>
        </div> 
      )}
      {horarioPlanificacion.length > 0 && (
        <Tabs
          className={css.tabs}
          tabs={horarioPlanificacion.map(clase=> ({
            name: clase.hora,
            label: <TabLabel hora={clase.hora} total={totals[clase.hora]} />,
            content: (
              <TabContent 
                asistencias={asistenciaList}
                pruebas={pruebaList}
                formaciones={formacionList}
                centro={clase.centro}
                isLoading={isAsistenciasLoading && isPruebasLoading && isFormacionesLoading}  
                onAsistenciaChange={handleAsistenciaClick}
                onPruebaChange={handlePruebaClick}
                onAsistenciaClick={handleAsistenciaAction}
                onPruebaClick={handlePruebaAction}
              />
            ),
          }))}
          activeTab={hora}
          onChange={setHora}
        />
      )}
      <ModalNuevaNotaProfesor
        open={!isBlank(asistenciaSeleccionada) && action === 'nota'}
        alumno={asistenciaSeleccionada?.alumno}
        onSubmit={handleAddNota}
        onClose={handleModalClose}
      />
      <ModalValoracionPrueba
        open={!isBlank(asistenciaSeleccionada) && action === 'valoracion'}
        lead={asistenciaSeleccionada?.feedbackNombre}
        valoracion={asistenciaSeleccionada?.valoracion}
        onSubmit={handleValoracionPrueba}
        onClose={handleModalClose}
      />
    </PageLoading>
  )


}

export default Planificacion