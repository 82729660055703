import { useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material"
import css from "./DatosAcceso.module.css"

const DatosAcceso = ({ accesoAlumno }) => {
  
  const [isExpanded, setExpanded] = useState(true)
  const handleChange = (_event, isExpanded) => setExpanded(isExpanded)

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary 
        expandIcon={<i className="material-icons">expand_more</i>}
      >
        <div className={css.label}>
          <Typography className={css.title} variant="h2">
            Email de acceso a Classfy
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {accesoAlumno?.accesoClassfy ? (
          <div className={css.info}>
            {!accesoAlumno.activo && (
              <Alert 
                icon={<i className='material-icons'>warning</i>} 
                severity='error'
              >
                Este acceso ha sido desactivado
              </Alert>
            )}
            <TextField
              label="Email"
              value={accesoAlumno.email}
              variant='standard'
            />
            <FormControlLabel
              control={<Checkbox checked={accesoAlumno.accesoClassfy} />}
              label='Email verificado'
            />
          </div>
        ) : (
          <>
            <Alert icon={<i className='material-icons'>warning</i>} className={css.alert} severity='warning'>
              Este alumno no tiene configurado acceso a <strong>Classfy</strong>
            </Alert>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default DatosAcceso
